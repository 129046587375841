import React from "react";
import "./App.css";

import { Container } from "@material-ui/core";
import { format } from "date-fns";

import Header from "../wh/components/Header";
import Heatmap from "../wh/components/Heatmap";
import ChartsOne from "../wh/components/ChartsOne";
import ChartsTwo from "../wh/components/ChartsTwo";
// import SummaryTable from "../wh/components/SummaryTable";
import About from "../wh/components/About";
import Footer from "../wh/components/Footer";
import Disclaimer from "../wh/components/Disclaimer";
import axios from "axios";
import { devUrl } from "../wh/constants/urls";
import { Redirect } from "react-router";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: "USA",
      distancingMeasures: "Unmitigated",
      date: "April 3, 2020",
      summaryDate: format(new Date(), "LLLL dd, yyyy"),
      chartState: "USA",
      availableStates: ["USA"],
      projectionDate: "",
      lastDate: "",
    };
  }

  async componentDidMount() {
    const response = await axios.get(`${devUrl}updatedOn/`);
    this.setState({
      projectionDate: response.data.updatedOn,
      lastDate: response.data.lastDate,
    });
  }

  _handleStateChange = (state) => {
    this.setState({
      selectedState: state,
    });
  };

  _handleChartStateChange = (state) => {
    this.setState({
      chartState: state,
    });
  };

  _handleDistancingMeasures = (measure) => {
    this.setState({
      distancingMeasures: measure,
    });
  };

  _handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  _handleSummaryDateChange = (summaryDate) => {
    this.setState({
      summaryDate,
    });
  };

  _handleAvailableStatesChange = (states) => {
    // we want to keep USA at the top of the array
    const allStates = [...states];
    allStates.splice(states.indexOf("USA"), 1);
    allStates.sort();
    allStates.unshift("USA");

    this.setState({
      availableStates: [...new Set(allStates)],
    });
  };

  render() {
    const {
      // selectedState,
      date,
      // summaryDate,
      availableStates,
      chartState,
      projectionDate,
      lastDate,
    } = this.state;

    if (sessionStorage.getItem("gleam-dashboard-auth") !== "true") {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <main style={{ flexGrow: 1 }}>
          <Container disableGutters maxWidth={false} style={{ marginTop: 0 }}>
            <Header />
            <Disclaimer projectionDate={projectionDate} />
            <Heatmap
              date={date}
              handleStateChange={this._handleStateChange}
              handleDateChange={this._handleDateChange}
              handleAvailableStatesChange={this._handleAvailableStatesChange}
            />

            <ChartsOne
              state={chartState}
              handleStateChange={this._handleChartStateChange}
              availableStates={availableStates}
            />
            <ChartsTwo state={chartState} />
          </Container>

          <Container maxWidth={false} disableGutters>
            <About />
          </Container>
          <Container maxWidth={false} style={{ backgroundColor: "#2a383c" }}>
            <Footer />
          </Container>
        </main>
      </div>
    );
  }
}

export default App;
