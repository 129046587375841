import React from "react";
import axios from "axios";
import { Redirect } from "react-router";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "", error: false, auth: false };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = async () => {
    const authUrl = "https://auth-api-dot-mobs-2019-ncov-web.appspot.com/auth";
    const response = await axios.post(authUrl, {
      password: this.state.value,
    });
    if (response.data === true) {
      sessionStorage.setItem("gleam-dashboard-auth", "true");
      this.setState({ auth: true, error: false });
    }
    this.setState({
      error: true,
    });
  };

  render() {
    if (this.state.auth) {
      return <Redirect to="/" />;
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "300px",
          flexDirection: "column",
        }}
      >
        <p>Enter password:</p>
        <input
          type="password"
          value={this.state.value}
          onChange={this.handleChange}
        />
        <button style={{ marginTop: 10 }} onClick={this.handleSubmit}>
          Submit
        </button>
        {this.state.error ? <p>Incorrect password.</p> : null}
      </div>
    );
  }
}

export default Login;
