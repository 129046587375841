import React from "react";

import { 
  Container, 
  Grid, 
  FormLabel,
  FormControl,
  // FormGroup,
  FormControlLabel,
  // Checkbox,
  Radio,
  RadioGroup,
  LinearProgress,
} from "@material-ui/core";

import axios from "axios";
import { format } from "date-fns";

import {
  Bar,
  Line,
  // ErrorBar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ReferenceLine,
  Area,
  ResponsiveContainer,
  Text,
  Tooltip,
  ComposedChart,
} from "recharts";

import { devUrl } from "../constants/urls";
// import { COLORS } from "../constants/colors";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icuBeds: false,
      hospitalBeds: true,
      data: [],
      scaleValue: "Linear",
      frequencyValue: "weekly",
      loading: true,
    };
  }

  async componentDidMount() {
    const { state } = this.props;
    const response = await axios.get(
      `${devUrl}datahospital?state=${state}&frequency=weekly`
    );
    this.setState({
      data: response.data,
      loading: false,
    });
  }

  async componentDidUpdate(prevProps) {
    const { state } = this.props;
    const { frequencyValue } = this.state;
    if (prevProps.state !== state) {
      const response = await axios.get(
        `${devUrl}datahospital?state=${state}&frequency=${frequencyValue}`
      );
      this.setState({
        data: response.data,
        loading: false,
      });
    }
  }

  _handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.checked });
  };

  _handleFrequencyChange = async (e) => {
    const { state } = this.props;
    const frequency = e.target.value;
    this.setState({ loading: true }, () => {
      axios
        .get(`${devUrl}datahospital?state=${state}&frequency=${frequency}`)
        .then((result) =>
          this.setState({
            loading: false,
            data: result.data,
            frequencyValue: frequency,
          })
        );
    });
  };

  getDataKeys = () => {
    // nonsense we have to do for recharts
    const { icuBeds, hospitalBeds, frequencyValue } = this.state;
    const dataKeys = [];
    const isWeekly = frequencyValue === "weekly";

    if (icuBeds) {
      let key = isWeekly
        ? "ICU beds needed|Mitigated_weekly"
        : "ICU beds needed|Mitigated";
      dataKeys.push(key);
    }

    if (hospitalBeds) {
      let key = isWeekly
        ? "Hospital beds needed|Mitigated_weekly"
        : "Hospital beds needed|Mitigated";
      dataKeys.push(key);
    }

    return dataKeys;
  };

  tickLabels = {
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "05": "5",
    "06": "6",
    "07": "7",
    "08": "8",
    "09": "9",
    "10": "10",
    "11": "11",
    "12": "12",
  };

  getLineName = (key) => {
    // formatting for tooltip / legend based on frequency value
    if (this.state.frequencyValue === "daily") {
      return `${key.split(`|`)[0]}, ${key.split(`|`)[1]} (Mean)`;
    }

    return `${key.split(`|`)[0]} (Mean)`;
  };

  kFormat = (tickItem) => {
    // format thousands into K, and millions into M
    return Math.abs(tickItem) > 999 && Math.abs(tickItem) < 999999
      ? Math.sign(tickItem) * (Math.abs(tickItem) / 1000).toFixed(1) + "K"
      : Math.abs(tickItem) > 999999
      ? Math.sign(tickItem) * (Math.abs(tickItem) / 1000000).toFixed(1) + "M"
      : tickItem;
  };

  getCurrentEpiWeek = () => {
    Date.prototype.getWeek = function () {
      var target = new Date(this.valueOf());    
      var dayPs = (this.getDay() + 7) % 7;
      target.setDate(target.getDate() - dayPs + 3);      
      var jan4 = new Date(target.getFullYear(), 0, 4);
      var dayDifference = (target - jan4) / 86400000;      
      if (new Date(target.getFullYear(), 0, 1).getDay() < 4) {
        return 1 + Math.ceil(dayDifference / 7);
      }
      else {
       return Math.ceil(dayDifference / 7);
      }
    };
    
    var weekNumber = new Date().getWeek();    
    var year = new Date().getFullYear();
    
    return year +'-' + (weekNumber );
  }; 

  getDateFromEpiWeek = (epi) => {
    var y = epi.split("-")[0];
    var w = epi.split("-")[1];
    var _days;
    if(w === 53){
    _days = (1 + (w - 1) * 7);
    }else{
    _days = (w * 7); 
    }
    var _date = new Date(y,0,_days);
    _date.setDate(_date.getDate() - _date.getDay() - 1);
    return _date.toLocaleDateString("en-US");
  }

  render() {
    const { 
      data, 
      scaleValue, 
      // frequencyValue 
    } = this.state;
    const dataKeys = this.getDataKeys();
    const dateToday = format(new Date(), "yyyy-MM-dd").toString();

    let icuCapacity = 0;

    // filter out 0s for log scale
    let chartData = [];
    if (this.state.scaleValue === "Logarithmic") {
      chartData = data.filter((row) => {
        const keys = Object.keys(row);
        let returnVal = true;
        keys.forEach((key) => {
          if (row[key] === 0) {
            returnVal = false;
          } else if (Array.isArray(row[key])) {
            if (row[key][0] === 0 || row[key[1]] === 0) {
              returnVal = true;
              console.log('final returnval: ', returnVal);
            }
          }
        });
        return returnVal;
      });
    } else {
      chartData = data;
    }

    if (chartData.length) {
      icuCapacity = chartData[0].icu_capacity || 0;
    }

    return (
      <Container maxWidth={false} disableGutters className="chartGrid" id="icubedproj">
        <Grid container spacing={0} style={{ marginTop: 2 }}>
          <Grid item xs></Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2>
                  Hospital Beds in {this.props.state}{" "}
                </h2>
              </Grid>
              <Grid item xs={12} sm={10} style={{ backgroundColor: "#ffffff" }}>
              {this.state.loading ? (
                  <div>
                    <LinearProgress />
                    <h4 style={{ textAlign: "center" }}>Loading data ... </h4>
                  </div>
                ) : (
                <ResponsiveContainer width="100%" height={600}>
                  <ComposedChart
                    className="chart"
                    margin={{
                      top: 10,
                      right: 60,
                      left: 0,
                      bottom: 80,
                    }}
                    data={chartData}
                    barSize={50}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis
                      label={{
                        value:
                          this.state.frequencyValue === "daily"
                            ? "Date"
                            : "",
                        position: "bottom",
                        dy: 10,
                        fontSize: 16,
                      }}
                      scale="auto"
                      tickFormatter={(tick) => {
                        const parts = tick.split("-");
                        return `${this.tickLabels[parts[1]]}/${parts[2]}/${parts[0]}`;
                      }}
                      dy={10}
                      dataKey={
                        this.state.frequencyValue === "daily"
                          ? "date"
                          : "date"
                      }
                      tick={{ fontSize: 14 }}
                      padding={{ left: 0, right: 0 }}
                      allowDuplicatedCategory={true}
                    />
                    <YAxis
                      tick={{ fontSize: 14 }}
                      tickFormatter={(tick) => {
                        return this.kFormat(tick);
                      }}
                      scale={scaleValue === "Logarithmic" ? "log" : "auto"}
                      domain={[0.01, dataMax=>(dataMax*3)]}
                      allowDataOverflow
                      label={
                        <Text
                          x={0}
                          y={0}
                          dx={15}
                          dy={250}
                          offset={0}
                          angle={-90}
                          fontSize={16}
                        >
                          Weekly Hospitalizations
                        </Text>
                      }
                    />
                    <Tooltip
                      cursor={{ stroke: "#4c5b5f", strokeWidth: 1 }}
                      formatter={(value, name) => {
                        if (Array.isArray(value)) {
                          if (value[0] && value[1]) {
                            return [
                              `[${Math.round(
                                value[0]
                              ).toLocaleString()} - ${Math.round(
                                value[1]
                              ).toLocaleString()}]`,
                              name,
                            ];
                          } else { 
                            return [null, null]; 
                          }
                        }
                        return [Math.round(value).toLocaleString(), name];
                      }}
                      labelFormatter={(value) => {
                        const parts = value.split("-");
                        return "Week ending on: " + `${parts[1]}/${parts[2]}/${parts[0]}`;
                      }}
                      // labelFormatter={(value) => 'Week ending on ' + this.getDateFromEpiWeek(value)}
                      itemStyle={{
                        fontSize: 14,
                        padding: "0 0 3px 5px",
                        margin: 0,
                        borderLeft: "3px solid",
                      }}
                      labelStyle={{
                        fontWeight: 600,
                        marginLeft: -20,
                        marginBottom: 10,
                        fontSize: 16,
                      }}
                    />
                    {data.length && (
                      <Legend
                        wrapperStyle={{
                          fontSize: 14,
                          paddingTop: 45,
                          paddingLeft: 10,
                        }}
                      />
                    )}
                    {this.state.frequencyValue === "daily" ? (
                      <ReferenceLine
                        x={dateToday}
                        label={{
                          value: "Today",
                          fill: "#7F7F7F",
                          position: "insideTopLeft",
                          fontSize: 12,
                          dy: 10,
                          fontWeight: 600,
                        }}
                        stroke="#7F7F7F"
                        strokeDasharray="5 5"
                        ifOverflow="extendDomain"
                      />
                    ) : null}

                    {dataKeys.map((key) => [
                      
                      <Bar
                        dataKey="sd_hospital"
                        name="Observed"
                        strokeWidth={0}
                        fill="#118687"
                        stroke="#118687"
                        stackId="hospitals"
                      />,

                      <Area
                        name="Projection Range"
                        dataKey={`range_${key}`}
                        stroke="#7ED66D"
                        fill="#7ED66D"
                        fillOpacity="0.25"
                        strokeOpacity="1"
                        strokeWidth={1}
                        legendType="none"
                        dot={false}
                        activeDot={true}
                      />,

                      <Area
                        name="IQR Range"
                        dataKey={`iqr_${key}`}
                        stroke="#7ED66D"
                        fill="#7ED66D"
                        fillOpacity="0.25"
                        strokeOpacity="1"
                        strokeWidth={1}
                        legendType="none"
                        dot={false}
                        activeDot={true}
                      />,

                      <Line
                        name="Projection"
                        dataKey={`amt_${key}`}
                        stroke="#118687"
                        strokeWidth={2}
                        fill="#118687"
                        activeDot={{ stroke: "white", strokeWidth: 2, r: 8 }}
                      />,

                      <ReferenceLine
                        x={this.getCurrentEpiWeek()}
                        label={{
                          value: "Current EpiWeek",
                          fill: "#7F7F7F",
                          position: "insideTopRight",
                          fontSize: 12,
                          dy: 0,
                          fontWeight: 600,
                        }}
                        stroke="#7F7F7F"
                        strokeDasharray="5 5"
                        ifOverflow="extendDomain"
                      />
                    ])}
                  </ComposedChart>
                </ResponsiveContainer>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" style={{fontSize: 14}}>Scale</FormLabel>
                    <RadioGroup
                      aria-label="scale"
                      name="scale"
                      id="scaleFilter"
                      value={scaleValue}
                      onChange={(e) =>
                        this.setState({
                          scaleValue: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="Linear"
                        control={<Radio />}
                        label="Linear"
                      />
                      <FormControlLabel
                        value="Logarithmic"
                        control={<Radio />}
                        label="Logarithmic"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Container>
    );
  }
}

export default Charts;
