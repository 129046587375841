import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Filters from "./Filters";
import april3map from "../heatmaps/maps-combined_2020-04-03.png";

import april17map from "../heatmaps/maps-combined_2020-04-17.png";
import may1map from "../heatmaps/maps-combined_2020-05-01.png";

const maps = {
  "April 3, 2020": april3map,
  "April 17, 2020": april17map,
  "May 1, 2020": may1map,
};

makeStyles((theme) => ({
  root: {
    paddingTop: 120,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

class Heatmap extends React.Component {
  render() {
    return (
      <Container maxWidth={false} id="key">
        <Grid
          item
          xs={12}
          style={{
            margin: 0,
            display: "none",
          }}
        >
          <Filters
            handleStateChange={this.props.handleStateChange}
            handleDateChange={this.props.handleDateChange}
            handleAvailableStatesChange={this.props.handleAvailableStatesChange}
          />
        </Grid>
        {/* <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            style={{
              mixBlendMode: "multiply",
              position: "relative",
              paddingBottom: 40,
            }}
          >
            <img
              width="100%"
              src={maps[this.props.date]}
              alt="Heatmap, April 3, 2020"
            />
          </Grid>
        </Grid> */}
      </Container>
    );
  }
}

export default Heatmap;
