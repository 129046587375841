import React from "react";
import axios from "axios";

import {
  Container,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
  Scatter,
} from "recharts";

import { productionServerUrl } from "../constants/urls";
import { COLORS } from "../constants/colors";
import { startCase } from "lodash";

class Reductions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      commutes: false,
      mobility: false,
      contacts: false,
      colocation: false,
      combined: true,
    };
  }

  async componentDidMount() {
    const dataResponse = await axios.get(`${productionServerUrl}reductions`);

    this.setState({
      data: dataResponse.data,
    });
  }

  getDataKeys = () => {
    const dataKeys = [];
    const { commutes, mobility, contacts, colocation, combined } = this.state;
    if (commutes) {
      dataKeys.push("amt_commutes");
    }

    if (mobility) {
      dataKeys.push("amt_mobility");
    }

    if (contacts) {
      dataKeys.push("amt_contacts");
    }

    if (colocation) {
      dataKeys.push("amt_colocation");
    }

    if (combined) {
      dataKeys.push("amt_combined");
    }

    return dataKeys;
  };

  getTooltipLabel(key) {
    const parts = key.split("_");
    return parts;
  }

  tickLabels = {
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "05": "5",
    "06": "6",
    "07": "7",
    "08": "8",
    "09": "9",
    "10": "10",
    "11": "11",
    "12": "12",
  };

  metrics = ["Commutes", "Mobility", "Contacts", "Colocation", "Combined"];

  render() {
    const dataKeys = this.getDataKeys();
    const { data } = this.state;
    return (
      <Container maxWidth={false} className="chartGrid" id="reductions" style={{background: "#fafafa", marginBottom: -30}}>
        <Grid container spacing={3} style={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <h2>Reductions</h2>
              </Grid>
              <Grid item sm={10} style={{marginLeft: 20}}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">Metric</FormLabel>
                  <FormGroup>
                    <Grid container xs={12}>
                    {this.metrics.map((metric) => {
                      return (
                        <Grid item xs={4} sm={4} md={2}>
                        <FormControlLabel
                          key={metric}
                          style={{ padding: "5px", fontSize: 12 }}
                          id="frequencyFilter"
                          control={
                            <Checkbox
                              checked={this.state[metric.toLowerCase()]}
                              onChange={(e) => {
                                this.setState({
                                  [e.target.name.toLowerCase()]: !this.state[
                                    e.target.name.toLowerCase()
                                  ],
                                });
                              }}
                              name={metric}
                            />
                          }
                          label={metric}
                        />
                        </Grid>
                      );
                    })}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={500}>
                  <ComposedChart
                    width={730}
                    height={250}
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 80 }}
                  >
                    <CartesianGrid stroke="#efefef" />
                    <XAxis
                      label={{
                        value: "State",
                        dy: 10,
                        position: "bottom",
                      }}
                      dataKey="state"
                      tick={{
                        fontSize: 11,
                      }}
                      interval={0}
                    />
                    <YAxis
                      label={{
                        value: "Reduction Index",
                        dx: -30,
                        fontSize: 16,
                        angle: -90,
                      }}
                      tick={{
                        fontSize: 12,
                      }}
                    />
                    <Tooltip
                      labelStyle={{
                        marginLeft: -20,
                        marginBottom: 10,
                        fontSize: 18,
                      }}
                      itemStyle={{
                        borderLeft: "3px solid",
                        padding: "0 0 3px 5px",
                      }}
                      formatter={(val) => {
                        return Number(val).toFixed(5) || val;
                      }}
                    />
                    <Legend
                      wrapperStyle={{
                        fontSize: 12,
                        marginBottom: 10,
                        paddingTop: 45,
                      }}
                    />
                    {dataKeys.map((key) => {
                      const lineComponents = [
                        <Scatter
                          type="monotone"
                          name={startCase(key.split("_")[1])}
                          dataKey={key}
                          fill={COLORS[dataKeys.indexOf(key)]}
                          key={`${key}_line`}
                          activeDot={{ r: 8 }}
                          strokeWidth={1}
                        />,
                      ];

                      return lineComponents;
                    })}
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Reductions;
