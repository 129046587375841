import React from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

class Intro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      last_updated: '',
      button_1_text: '',
      button_1_link: '',
      button_2_text: '',
      button_2_link:  '',
      map_panel_title: '',
      map_panel_description:'',
      contacts_panel_text: '',
      contacts_panel_description: '',
    };
  }

  async componentDidMount() {
    fetch('https://storage.googleapis.com/gs_web_covid19/texts/text_mobility.json', {
      method: "GET",
      cache: "no-store",
    })
      .then((r) => r.json())
      .then(text  => {
        console.log("content object: ", text);
        this.setState({
          title: text.title,
          description: text.description,
          last_updated: text.last_updated,
          button_1_text: text.button_1.text,
          button_1_link: text.button_1.link,
          button_2_text: text.button_2.text,
          button_2_link: text.button_2.link,
        })
      }); 
  }

  render() {
    return (
      <div id="intro">
        <h1>
          {this.state.title}
        </h1>
        <Divider light />
        {ReactHtmlParser(this.state.description)}
        <p><strong>{this.state.last_updated}</strong></p>
        
        <Button
          variant="contained"
          size="large"
          disableElevation
          color="primary"
          style={{ marginRight: 10 }}
          href={this.state.button_1_link}
          target="_blank"
        >
          <PictureAsPdfIcon style={{ marginRight: 10 }} /> {this.state.button_1_text}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          style={{ marginRight: 10 }}
          href={this.state.button_2_link}
          target="_blank"
        >
          <PictureAsPdfIcon style={{ marginRight: 10 }} /> {this.state.button_2_text}
        </Button>
      </div>
    );
  }
}

export default Intro;
