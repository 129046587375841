import React from "react";

import {
  Container,
  Grid,
  LinearProgress,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import axios from "axios";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Area,
  ResponsiveContainer,
  Text,
  Tooltip,
  ComposedChart,
  ReferenceLine,
} from "recharts";

import { italyDevUrl } from "../constants/urls";
import { COLORS } from "../constants/colors";
import { format } from "date-fns";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icuBeds: false,
      hospitalBeds: true,
      data: [],
      scaleValue: "Linear",
      frequencyValue: "weekly",
      scenarios: [],
      selectedScenarios: {},
      loading: false,
    };
  }

  async componentDidMount() {
    const { state, scenarios } = this.props;
    const response = await axios.get(
      `${italyDevUrl}data?state=${state}&frequency=weekly`
    );
    const updatedState = {};
    scenarios.forEach((scenario) => (updatedState[scenario] = false));
    updatedState["data"] = response.data;
    this.setState(updatedState);
  }

  async componentDidUpdate(prevProps) {
    const { state, scenarios } = this.props;
    const { frequencyValue } = this.state;
    if (prevProps.state !== state) {
      const response = await axios.get(
        `${italyDevUrl}data?state=${state}&frequency=${frequencyValue}`
      );
      this.setState({
        data: response.data,
      });
    }

    if (prevProps.scenarios !== scenarios) {
      const updatedState = {
        selectedScenarios: {},
      };
      scenarios.forEach((scenario) => {
        if (scenario === "Lockdown kept indefinitely") {
          updatedState["selectedScenarios"][scenario] = true;
        } else {
          updatedState["selectedScenarios"][scenario] = false;
        }
      });
      this.setState(updatedState);
    }
  }

  _handleScenarioChange = (e) => {
    const selectedScenarios = { ...this.state.selectedScenarios };
    selectedScenarios[e.target.name] = e.target.checked;
    this.setState({ selectedScenarios });
  };

  _handleMetricChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.checked });
  };

  _handleFrequencyChange = async (e) => {
    const { state } = this.props;
    const frequency = e.target.value;
    this.setState({ loading: true }, () => {
      axios
        .get(`${italyDevUrl}data?state=${state}&frequency=${frequency}`)
        .then((result) =>
          this.setState({
            loading: false,
            data: result.data,
            frequencyValue: frequency,
          })
        );
    });
  };

  getDataKeys = () => {
    // nonsense we have to do for recharts
    const {
      icuBeds,
      hospitalBeds,
      frequencyValue,
      selectedScenarios,
    } = this.state;
    const dataKeys = [];
    const isWeekly = frequencyValue === "weekly";

    const scenarios = Object.keys(selectedScenarios).filter(
      (key) => selectedScenarios[key]
    );

    if (icuBeds) {
      scenarios.forEach((scenario) => {
        let key = isWeekly
          ? `ICU beds needed|${scenario}_weekly`
          : `ICU beds needed|${scenario}`;
        dataKeys.push(key);
      });
    }

    if (hospitalBeds) {
      scenarios.forEach((scenario) => {
        let key = isWeekly
          ? `Hospital beds needed|${scenario}_weekly`
          : `Hospital beds needed|${scenario}`;
        dataKeys.push(key);
      });
    }

    return dataKeys;
  };

  tickLabels = {
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "05": "5",
    "06": "6",
    "07": "7",
    "08": "8",
    "09": "9",
    "10": "10",
    "11": "11",
    "12": "12",
  };

  getTruncatedLabel = (label) => {
    const labels = {
      "No Lockdown": "No Lockdown",
      "Lockdown kept indefinitely": "Lockdown kept indefinitely",
      "Lift 1 (May 4th): 50% work sector; 10% community; schools closed; 50% mobility":
        "Lift 1",
      "Lift 2 (May 4th): 70% work sector; 50% community; schools closed; 50% mobility":
        "Lift 2",
      "Full Lift (May 4th): social distancing measures are lifted. Full mobility resumed. Schools and education activities are resumed":
        "Full Lift",
    };
    return labels[label] || label;
  };

  getLineName = (key) => {
    // formatting for tooltip / legend based on frequency value
    if (this.state.frequencyValue === "daily") {
      return `${key.split(`|`)[0]}, ${this.getTruncatedLabel(
        key.split(`|`)[1]
      )} (Mean)`;
    }

    return `${key.split(`|`)[0]}, ${this.getTruncatedLabel(
      key.split(`|`)[1].split("_")[0]
    )} (Mean)`;
  };

  kFormat = (tickItem) => {
    // format thousands into K, and millions into M
    return Math.abs(tickItem) > 999 && Math.abs(tickItem) < 999999
      ? Math.sign(tickItem) * (Math.abs(tickItem) / 1000).toFixed(1) + "K"
      : Math.abs(tickItem) > 999999
      ? Math.sign(tickItem) * (Math.abs(tickItem) / 1000000).toFixed(1) + "M"
      : tickItem;
  };

  scenarioIndices = {
    0: "Lockdown kept indefinitely",
    1: "Lift 1 (May 4th): 50% work sector; 10% community; schools closed; 50% mobility",
    2: "Lift 2 (May 4th): 70% work sector; 50% community; schools closed; 50% mobility",
    3: "Full Lift (May 4th): social distancing measures are lifted. Full mobility resumed. Schools and education activities are resumed",
  };

  render() {
    const { data, scaleValue, frequencyValue } = this.state;
    const { scenarios } = this.props;
    const dataKeys = this.getDataKeys();
    const dateToday = format(new Date(), "yyyy-MM-dd").toString();

    // filter out 0s for log scale
    let chartData = [];
    if (this.state.scaleValue === "Logarithmic") {
      chartData = data.filter((row) => {
        const keys = Object.keys(row);
        let returnVal = true;
        keys.forEach((key) => {
          if (key.includes("amt") || key.includes("range")) {
            if (row[key] === 0) {
              returnVal = false;
            } else if (Array.isArray(row[key])) {
              if (row[key][0] === 0 || row[key[1]] === 0) {
                returnVal = false;
              }
            }
          }
        });
        return returnVal;
      });
    } else {
      chartData = data;
    }

    return (
      <Container maxWidth={false} className="chartGrid" id="icubedproj">
        <Grid container spacing={3} style={{ marginTop: 2 }}>
          <Grid item xs></Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2>Hospital Bed Projections for {this.props.state} </h2>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" style={{ fontSize: 14 }}>
                        Scale
                      </FormLabel>
                      <RadioGroup
                        aria-label="scale"
                        name="scale"
                        id="scaleFilter"
                        value={scaleValue}
                        onChange={(e) =>
                          this.setState({
                            scaleValue: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="Linear"
                          control={<Radio />}
                          label="Linear"
                        />
                        <FormControlLabel
                          value="Logarithmic"
                          control={<Radio />}
                          label="Logarithmic"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" style={{ fontSize: 14 }}>
                        Frequency
                      </FormLabel>
                      <RadioGroup
                        aria-label="frequency"
                        name="frequency"
                        id="frequencyFilter"
                        value={frequencyValue}
                        onChange={this._handleFrequencyChange}
                      >
                        <FormControlLabel
                          value="weekly"
                          control={<Radio />}
                          label="Weekly"
                        />
                        <FormControlLabel
                          value="daily"
                          control={<Radio />}
                          label="Daily"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={9} style={{ backgroundColor: "#ffffff" }}>
                {this.state.loading ? (
                  <div>
                    <LinearProgress />
                    <h4 style={{ textAlign: "center" }}>Loading data ... </h4>
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                      className="chart"
                      margin={{
                        top: 10,
                        right: 60,
                        left: 0,
                        bottom: 80,
                      }}
                      data={chartData}
                    >
                      <CartesianGrid stroke="#f5f5f5" />
                      <XAxis
                        label={{
                          value:
                            this.state.frequencyValue === "daily"
                              ? "Date"
                              : "Epiweek",
                          position: "bottom",
                          dy: 10,
                          fontSize: 16,
                        }}
                        interval={
                          this.state.frequencyValue === "daily"
                            ? "preserveEnd"
                            : 2
                        }
                        tickFormatter={(tick) => {
                          if (this.state.frequencyValue === "weekly") {
                            return tick;
                          }
                          const parts = tick.split("-");
                          return `${this.tickLabels[parts[1]]}/${parts[2]}`;
                        }}
                        dy={10}
                        dataKey={
                          this.state.frequencyValue === "daily"
                            ? "date"
                            : "epi_week"
                        }
                        tick={{ fontSize: 14 }}
                        padding={{ left: 0, right: 0 }}
                        allowDuplicatedCategory={false}
                      />
                      <YAxis
                        tick={{ fontSize: 14 }}
                        tickFormatter={(tick) => {
                          return this.kFormat(tick);
                        }}
                        scale={scaleValue === "Logarithmic" ? "log" : "auto"}
                        domain={[0.01, "auto"]}
                        label={
                          <Text
                            x={0}
                            y={0}
                            dx={15}
                            dy={250}
                            offset={0}
                            angle={-90}
                            fontSize={16}
                          >
                            Number Affected
                          </Text>
                        }
                      />
                      <Tooltip
                        cursor={{ stroke: "#4c5b5f", strokeWidth: 1 }}
                        formatter={(value, name) => {
                          if (Array.isArray(value)) {
                            return [
                              `[${Math.round(
                                value[0]
                              ).toLocaleString()} - ${Math.round(
                                value[1]
                              ).toLocaleString()}]`,
                              name,
                            ];
                          }
                          return [Math.round(value).toLocaleString(), name];
                        }}
                        itemStyle={{
                          fontSize: 14,
                          padding: "0 0 3px 5px",
                          margin: 0,
                          borderLeft: "3px solid",
                        }}
                        labelStyle={{
                          fontWeight: 600,
                          marginLeft: -20,
                          marginBottom: 10,
                          fontSize: 16,
                        }}
                      />
                      {data.length && (
                        <Legend
                          wrapperStyle={{
                            fontSize: 14,
                            paddingTop: 45,
                            paddingLeft: 10,
                          }}
                        />
                      )}
                      {dataKeys.map((key) => [
                        <Line
                          name={this.getLineName(key)}
                          type="monotone"
                          dataKey={`amt_${key}`}
                          stroke={COLORS[dataKeys.indexOf(key)]}
                          strokeWidth={3}
                          fill={COLORS[dataKeys.indexOf(key)]}
                          connectNulls
                          dot={false}
                          activeDot={{ stroke: "white", strokeWidth: 2, r: 9 }}
                        />,
                        <Area
                          name="Projection Range"
                          dataKey={`range_${key}`}
                          stroke={COLORS[dataKeys.indexOf(key)]}
                          fill={COLORS[dataKeys.indexOf(key)]}
                          fillOpacity="0.2"
                          strokeOpacity="0.6"
                          strokeDasharray="3 3"
                          legendType="none"
                          dot={false}
                          activeDot={false}
                        />,
                      ])}
                      {this.state.frequencyValue === "daily" ? (
                        <ReferenceLine
                          x={dateToday}
                          label={{
                            value: "Today",
                            fill: "#7F7F7F",
                            position: "insideTopLeft",
                            fontSize: 12,
                            dy: 10,
                            fontWeight: 600,
                          }}
                          stroke="#7F7F7F"
                          strokeDasharray="5 5"
                          ifOverflow="extendDomain"
                        />
                      ) : null}
                    </ComposedChart>
                  </ResponsiveContainer>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Scenario</FormLabel>
                    <FormGroup>
                      {scenarios
                        .map((scenario, index) => {
                          return this.scenarioIndices[index] || scenario;
                        })
                        .map((scenario) => {
                          return scenario !== "No Lockdown" ? (
                            <FormControlLabel
                              style={{ padding: "5px" }}
                              key={scenario}
                              control={
                                <Checkbox
                                  checked={
                                    this.state["selectedScenarios"][scenario] ||
                                    false
                                  }
                                  onChange={this._handleScenarioChange}
                                  name={scenario}
                                />
                              }
                              label={scenario}
                            />
                          ) : null;
                        })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Container>
    );
  }
}

export default Charts;
