import React from "react";
import "./App.css";

import { Container } from "@material-ui/core";
import { format } from "date-fns";

import Header from "../italy-public/components/Header";
import Heatmap from "../italy-public/components/Heatmap";
import ChartsOne from "../italy-public/components/ChartsOne";
import ChartsTwo from "../italy-public/components/ChartsTwo";
import SummaryTable from "../italy-public/components/SummaryTable";
import About from "../italy-public/components/About";
import Footer from "../italy-public/components/Footer";
import Disclaimer from "../italy-public/components/Disclaimer";
import axios from "axios";
import { italyDevUrl } from "../italy-public/constants/urls";
import { Redirect } from "react-router";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: "Italy",
      distancingMeasures: "Unmitigated",
      date: "April 3, 2020",
      summaryDate: format(new Date(), "LLLL dd, yyyy"),
      chartState: "Italy",
      availableStates: ["Italy"],
      projectionDate: "",
      lastDate: "",
      scenarios: [],
    };
  }

  async componentDidMount() {
    const [response, scenarios] = await Promise.all([
      axios.get(`${italyDevUrl}updatedOn/`),
      axios.get(`${italyDevUrl}scenarios/`),
    ]);
    this.setState({
      projectionDate: response.data.updatedOn,
      lastDate: response.data.lastDate,
      scenarios: scenarios.data,
    });
  }

  _handleStateChange = (state) => {
    this.setState({
      selectedState: state,
    });
  };

  _handleChartStateChange = (state) => {
    this.setState({
      chartState: state,
    });
  };

  _handleDistancingMeasures = (measure) => {
    this.setState({
      distancingMeasures: measure,
    });
  };

  _handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  _handleSummaryDateChange = (summaryDate) => {
    this.setState({
      summaryDate,
    });
  };

  _handleAvailableStatesChange = (states) => {
    // we want to keep ITA at the top of the array
    const allStates = [...states];

    const regions = [
      "Italy",
      "Northern Italy",
      "Central Italy",
      "Southern Italy",
      "Sardegna",
      "Sicilia",
    ];

    this.setState({
      availableStates: [...new Set([...regions, ...allStates])],
    });
  };

  render() {
    const {
      selectedState,
      date,
      summaryDate,
      availableStates,
      chartState,
      projectionDate,
      lastDate,
    } = this.state;

    if (sessionStorage.getItem("gleam-dashboard-auth") !== "true") {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <main style={{ flexGrow: 1 }}>
          <Container disableGutters maxWidth={false} style={{ marginTop: 0 }}>
            <Header />
            <Disclaimer projectionDate={projectionDate} />
            <h2 style={{ paddingLeft: "20px" }}>
              Projections Timeline Under The Lockdown Scenario
            </h2>
            <Heatmap
              date={date}
              handleStateChange={this._handleStateChange}
              handleDateChange={this._handleDateChange}
              handleAvailableStatesChange={this._handleAvailableStatesChange}
            />
            <SummaryTable
              state={selectedState}
              date={summaryDate}
              handleDateChange={this._handleSummaryDateChange}
              lastDate={lastDate}
            />
            <ChartsOne
              state={chartState}
              handleStateChange={this._handleChartStateChange}
              availableStates={availableStates}
              scenarios={this.state.scenarios}
            />
            <ChartsTwo state={chartState} scenarios={this.state.scenarios} />
          </Container>

          <Container maxWidth={false} disableGutters>
            <About />
          </Container>
          <Container maxWidth={false} style={{ backgroundColor: "#2a383c" }}>
            <Footer />
          </Container>
        </main>
      </div>
    );
  }
}

export default App;
